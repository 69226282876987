
import { defineComponent, PropType } from 'vue'
import { Block } from '~/domain/interfaces/BlockTypes'
export default defineComponent({
  name: 'HomepagePerformanceBoost',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },
})
